import React, { useEffect } from "react"
import Search from "../../components/home/search"
import { isEmpty } from 'lodash'
import Layout from "../../components/layout"
// import Carousel from "../../components/home/carousel"
// import HeroMob from "../../images/hero-mob.jpg"
import Link from "gatsby-link"
import SEO from "../../components/seo";
import Revs from "../../components/reviews";
import SliderIn from "../../components/slider2.js";
import SliderPhoto from "../../components/slider";
import BlogModule from "../../components/post-module";


// import { StaticImage }  from "gatsby-plugin-image"





const FrontPage = ( props ) => {

	useEffect(() => {
	console.log('Welcome to shroom drinks!')
	}, []);

	const {
		      pageContext: {
			      //page: {  seo, uri },
			      categories,
			      categoryName,
			      postSearchData: { products, options }
		      }
	      } = props;

	return (
		<Layout>
			{
				! isEmpty( props.pageContext ) ? (
					<>
					<SEO
						title={ 'Healthy & natural energy drinks' }
						description={"The healthy, natural energy drinks with cordyceps militaris, lion's mane and l'theanine."}
						header={ { siteTitle: ' Shroom Drinks | Shop' } }
					/>

						{/* Hero */}
						<div id="hwrap" className="container-fluid mush" style={{
								padding:'0',
								minHeight: '600px',
								marginTop:'-30px',
								backgroundSize:'cover',
								backgroundPosition:'center',
								backgroundRepeat:'no-repeat',
						  }}>
						  <div id="hero" className="mask container-fluid" style={{backgroundColor: 'rgba(0, 0, 0, 0.33)',display:'flex',alignItems:'center',flexWrap:'wrap',width:'100%',minWidth:'100%',height:'100%',minHeight:'800px',justifyContent:'center'}}>
								<div className="col-8" style={{minHeight: '400px',display:'block',textAlign:'center'}}>
								<span className="spec-h"></span>
								<h1 className="text-white mob-hero spec-h" style={{textAlign:'center',fontSize:'5em',wordBreak:'break-word'}}>shroom drink</h1>
								<br />
								<h2 className="spec-h text-white" style={{textAlign:'center',fontSize:'1.2em'}}>The healthy, natural energy drinks with cordyceps militaris, lion's mane and l'theanine.<br /><br />Our drinks will balance your energy!</h2>
								<p className="text-white" style={{textAlign:'center',margin:'auto',maxWidth:'500px',color:'#111'}}>
								<br /><br />
								<a className="btn btn-outline-light spec-h btn-lg" href="#archive" role="button" style={{fontVariant:'small-caps'}}>see products</a>
								</p>
								<br />
									<p className="spec-h text-white" >Discover the treasures of the funghi kingdom!</p>
									<div style={{padding:'2em',textAlign:'center',color:'white'}}>
									<a id="go" href="#mushroom-drinks" style={{fontSize:'15px'}}>⇩</a>
									</div>
								</div>
						  </div>
						</div>

          {/* Categories module */}
          <div id="mushroom-drinks" style={{width:'100%',padding:'2.5em 0',backgroundColor:'#E8D3D0'}}>
            <br />
            <div className="row container-fluid" style={{margin:'auto'}}>
            <div className="column col mx-auto" style={{ height: '750px', position: 'relative' }}>
              <a href="#archive">
                <div className="home-mod" style={{ position: 'relative', overflow: 'hidden', padding: '1em', margin: '1em', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                  {/* Overlay container */}
                  <div style={{ position: 'absolute', top: '20px', left: 'auto', padding: '1em', color: '#1D1D1D', zIndex: 2, maxWidth:'362px' }}>
                    <h2 className="spec-h home-m">Shroom drinks</h2>
                    <p>Probably the <span className="spec-h">#1 mushroom</span> functional drink in Europe.</p>
                    <br />
                    <p style={{textAlign:'center',maxWidth:'100%'}}><span className="btn">Learn more</span></p>
                  </div>
                  <video
                    style={{
                      height: '650px',
                      boxShadow: '5px 5px 20px rgba(23,19,43,.2)',
                      borderRadius: '25px',
                      margin: 'auto',
                      maxWidth: '100%',
                      objectFit: 'cover',
                    }}
                    autoPlay="autoplay"
                    loop="loop"
                    muted
                    defaultmuted="true"
                    playsInline
                    onContextMenu={()=>{return false}}
                    loading="lazy"
                    src="https://admin.shroom4you.com/wp-content/uploads/2024/01/ANIMACJA_11.mp4"
                  ></video>
                </div>
              </a>
            </div>
              <div className="column col mx-auto mod-r" style={{height:'750px'}}>

                <a href="#accessories">
                <div className="home-mod" style={{position: 'relative', overflow: 'hidden', padding: '1em', margin: '1em'}}>

                {/* Overlay container */}
                <div style={{ position: 'absolute', top: '20px', left: 'auto', padding: '1em', color: '#FEFEFE', zIndex: 2, width:'100%'}}>
                  <h2 className="spec-h home-m">Accessories</h2>
                  <p>The original <span className="spec-h">shroom</span> accessories. <b>Mushroomly</b>! </p>
                  <br />
                  <p style={{textAlign:'center'}}><span className="btn">Learn more</span></p>
                </div>

                  <img src="https://admin.shroom4you.com/wp-content/uploads/2024/01/acc.jpg"
                    alt=""
                    loading="lazy"
                    style={{
                       gridArea: "1/1",
                       width:'100%',
                       objectFit:'cover',
                       minHeight:'300px',
                       maxWidth: '100%',
                       boxShadow:'5px 5px 20px rgba(23,19,43,.2)',
                       borderRadius:'25px',
                     }}
                     />
                </div>
                </a>

                <Link to="/shroomscriptions/">
                  <div className="home-mod" style={{position: 'relative', overflow: 'hidden', padding: '1em', margin: '1em'}}>
                  {/* Overlay container */}
                  <div style={{ position: 'absolute', top: '20px', left: 'auto', padding: '1em', color: '#FEFEFE', zIndex: 2, width:'100%'}}>
                    <h2 className="spec-h home-m">Shroomscriptions</h2>
                    <p>Never run out of <span className="spec-h">shroom</span>. Get more for less at a monthly rate!
                    </p>
                    <br />
                    <p style={{textAlign:'center'}}><span className="btn">Learn more</span></p>
                  </div>

                    <img src="https://admin.shroom4you.com/wp-content/uploads/2024/01/shroomscriptions.jpg"
                      alt=""
                      loading='lazy'
                      style={{
                         gridArea: "1/1",
                         width:'100%',
                         objectFit:'cover',
                         minHeight:'300px',
                         maxWidth: '100%',
                         boxShadow:'5px 5px 20px rgba(23,19,43,.2)',
                         borderRadius:'25px',
                       }}
                       />
                  </div>
                </Link>

                <div style={{marginTop:'30px'}}> </div>
                </div>
            </div>
          </div>



            <div className="container-fluid" style={{textAlign:'center',padding:'1.5em 15px',backgroundColor:'#FCFAF6'}}>
            <h2 className="spec-h my-2"> The first EU functional drink<br /> with medicinal mushrooms and adaptogens.
            <br />
            <br />
            <sub id="archive" className="spec-h"> FEEL BETTER WITH EVERY SIP </sub> </h2>
            </div>

            {/*<Carousel categories={ categories }/>*/}

						<Search
							products={ products }
							initialProducts={ products }
							engine={ options }
							category={ categoryName }
							categories={ categories }
						/>
					</>
				) : (
					<div>Something went wrong</div>
				)
			}


			<Revs />
      <SliderPhoto />
			<div className="container-fuild" style={{alignItems:'center',justifyContent:'center',textAlign:'center',margin:'5em 0em',padding:'2em 1em'}}>
			<h2 className="spec-h">
			Learn more about our ingredients!
			</h2>
			<p>
			Shroom drinks are real superfoods, or more likely superdrinks! Check out their amazing ingredients.
			</p>
			<br /><br />
			<SliderIn />
			<br /><br /><br />
			<p style={{textAlign:'center'}}>
			<Link to="/our-story/" className="btn btn-outline-dark mush">Get to know us better</Link>
			</p>
			</div>
      <BlogModule />
      <br />
			<p style={{textAlign:'center'}}>
			<a target="_blank" rel="noreferrer" href="https://shroom4you.com/blog/" className="btn btn-outline-dark mush">Visit blog</a>
			</p>
      <br /><br />
		</Layout>
	)
};
export default FrontPage;
